/**
 * Created by PanJiaChen on 16/11/18.
 */
/**
 * @param {*} array
 * @param {*} key 不传代表数组，传则是数组对象
 * @returns
 */
export function sortSize(array, key) {
  const wordSize = 'F,S,M,L,XL,XXL,XXXL'
  const result = JSON.parse(JSON.stringify(array))
  result.sort(function (a, b) {
    const v1 = key ? a[key] : a
    const v2 = key ? b[key] : b
    let compare = 0
    if (!isNaN(v1)) {
      compare = v1 - v2
    } else {
      compare = wordSize.indexOf(v1) - wordSize.indexOf(v2)
    }
    if (compare < 0) {
      return -1
    } else if (compare === 0) {
      return compare
    } else {
      return 1
    }
  })
  return result
}

/**
 * 获取用户信息
 * @returns
 */
export function getUserInfo() {
  return JSON.parse(localStorage.getItem('userInfo'))
}
export function getlogoInformation() {
  return JSON.parse(localStorage.getItem('logoInformation'))
}
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xdc00 && code <= 0xdfff) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach((v) => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

export function formatFloat(src, pos) {
  //四舍五入,保留2位小数
  if (src === '' || src === undefined || src === null) {
    return ''
  } else {
    if (pos == null) {
      pos = 2
    }
    return Math.round(src * Math.pow(10, pos)) / Math.pow(10, pos)
  }
}

export function formatPercent(src, pos = 2, withUnit = '') {
  //四舍五入,保留2位小数 再算出百分比
  let num = 0
  if (src && src != 0) {
    src = src * 100
    num = Math.round(src * Math.pow(10, pos)) / Math.pow(10, pos)
  }
  return `${num}${withUnit}`
}

export function formDiscount(src) {
  const data = formatPercent(src, 0)
  if (data > 100) {
    return '大于100%'
  } else if (data < 0) {
    return '小于0%'
  } else {
    if (data < 10) {
      return `0.${data}折`
    } else {
      return data % 10 === 0 ? `${data / 10}折` : `${data}折`
    }
  }
}

export function formatInteger(src) {
  if (src == '' || src == undefined) {
    return '--'
  } else {
    return parseInt(src)
  } //取整数
}

export function number(value) {
  return value.replace(/[^\d.]/g, '')
}

export function sortByKey(array, key) {
  return array.sort(function (a, b) {
    let x = a[key]
    let y = b[key]
    return x < y ? -1 : x > y ? 1 : 0
  })
}
Date.prototype.clone = function () {
  return new Date(this.valueOf())
}
Date.prototype.format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return fmt
  //yyyy-MM-dd hh:mm:ss日期格式
}
export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
export function dateFormat(time) {
  if (!time) return ''
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else if (('' + time).indexOf('-') > 0 || ('' + time).indexOf('/') > 0) {
    return time.substring(0, 10)
  }
  const d = new Date(time)
  return d.format('yyyy-MM-dd')
}

export function dateTimesFormat(time) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else if (('' + time).indexOf('-') > 0 || ('' + time).indexOf('/') > 0) {
    return time
  }
  const d = new Date(time)
  return d.format('yyyy-MM-dd hh:mm:ss')
}

export function deepCopy(obj) {
  let target = null
  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      //数组
      target = []
      obj.forEach((item) => {
        target.push(deepCopy(item))
      })
    } else if (obj) {
      target = {}
      let objKeys = Object.keys(obj)
      objKeys.forEach((key) => {
        target[key] = deepCopy(obj[key])
      })
    } else {
      target = obj
    }
  } else {
    target = obj
  }
  return target
}

export function CheckInputFloat(value) {
  value = value + ''
  if (value && '' != value.replace(/\d{1,}\.{0,1}\d{0,}/, '')) {
    value = value.match(/\d{1,}\.{0,1}\d{0,}/) == null ? '' : value.match(/\d{1,}\.{0,1}\d{0,}/)
  }
  return value
}

export function CheckInputInt(value) {
  value = value + ''
  if (value && '' != value.replace(/\d{1,}/, '')) {
    value = value.match(/\d{1,}/) == null ? '' : value.match(/\d{1,}/)
  }
  return value
}
export function CheckInputUpper(value) {
  value = value + ''
  if (value) {
    value = value.toUpperCase()
  }
  return value
}
export function CheckInputIntLGZero(value) {
  value = value + ''
  if (value && '' != value.replace(/^[1-9]\d{0,}$/, '')) {
    value = value.match(/^[1-9]\d{0,}$/) == null ? '' : value.match(/^[1-9]\d{0,}$/)
  }
  return value
}

export function isInt(value) {
  value = value + ''
  var re = /^\d+$/ // /^[1-9]+[0-9]*]*$/;
  if (re.test(value)) {
    return true
  } else {
    return false
  }
}
export function isFloat(value) {
  value = value + ''
  var partten = /(^[1-9][0-9]*[.]{0,1}[0-9]*[0-9]$)|(^[0].[0-9]*[0-9]$)|(^[0-9]$)/
  if (partten.test(value)) {
    return true
  } else {
    return false
  }
}

export function getValue(value, prefix, suffix) {
  if (
    value == null ||
    value == '' ||
    typeof value == 'undefined' ||
    (typeof value === 'number' && isNaN(value))
  ) {
    return '--'
  } else {
    if (prefix) {
      value = prefix + value
    }
    if (suffix) {
      value = value + suffix
    }
    return value
  }
}
export function getCountryCode(value) {
  if (value == 'ATVPDKIKX0DER') {
    value = 'US'
  }
  if (value == 'A13V1IB3VIYZZH') {
    value = 'FR'
  }
  if (value == 'A17E79C6D8DWNP') {
    value = 'SA'
  }
  if (value == 'A1805IZSGTT6HS') {
    value = 'NL'
  }
  if (value == 'A19VAU5U5O7RUS') {
    value = 'SG'
  }
  if (value == 'A1AM78C64UM0Y8') {
    value = 'MX'
  }
  if (value == 'A1C3SOZRARQ6R3') {
    value = 'PL'
  }
  if (value == 'ATVPDKIKX0DER') {
    value = 'US'
  }
  if (value == 'A1F83G8C2ARO7P') {
    value = 'UK'
  }
  if (value == 'A1PA6795UKMFR9') {
    value = 'DE'
  }
  if (value == 'A1RKKUPIHCS9HS') {
    value = 'ES'
  }
  if (value == 'A1VC38T7YXB528') {
    value = 'JP'
  }
  if (value == 'A21TJRUUN4KGV') {
    value = 'IN'
  }
  if (value == 'A2EUQ1WTGCTBG2') {
    value = 'CA'
  }
  if (value == 'A2NODRKZP88ZB9') {
    value = 'SE'
  }
  if (value == 'A2Q3Y263D00KWC') {
    value = 'BR'
  }
  if (value == 'A2VIGQ35RCS4UG') {
    value = 'AE'
  }
  if (value == 'A33AVAJ2PDY3EV') {
    value = 'TR'
  }
  if (value == 'A39IBJ37TRP1C6') {
    value = 'AU'
  }
  if (value == 'APJ6JRA9NG5V4') {
    value = 'IT'
  }
  if (value == 'ARBP9OOSHTCHU') {
    value = 'EG'
  }
  return value
}

export function getCurrencyMark(currency) {
  var fcuurency = ''
  if (currency != null) {
    if (currency == 'USD') {
      fcuurency = '$'
    }
    if (currency == 'GBP') {
      fcuurency = '£'
    }
    if (currency == 'EUR') {
      fcuurency = '€'
    }
    if (currency == 'JPY') {
      fcuurency = '¥'
    }
    if (currency == 'CNY') {
      fcuurency = '¥'
    }
    if (currency == 'RMB') {
      fcuurency = '¥'
    }
    if (currency == 'CAD') {
      fcuurency = 'C$'
    }
    if (currency == 'INR') {
      fcuurency = '₹'
    }
    if (currency == 'AUD') {
      fcuurency = 'A$'
    }
    if (currency == 'MXN') {
      fcuurency = 'Mex$'
    }
    if (currency == 'SEK') {
      fcuurency = 'Kr'
    }
    if (currency == 'PLN') {
      fcuurency = 'zł'
    }
    return fcuurency
  } else {
    return ''
  }
}
export function getDateValue(value) {
  if (value) {
    if (('' + value).length === 10) {
      value = parseInt(value) * 1000
    } else if (('' + value).indexOf('-') > 0 || ('' + value).indexOf('/') > 0) {
      value = new Date(value)
    }
  }
  return value
}
