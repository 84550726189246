export default [
    {
        path: 'erp/s/re/mendiordeReport/detail',
        name: 'mendior',
        component: () => import('@/views/erp/sells/reportforms/mendiordeReport/components/details.vue')
    },
    {
        path: 'erp/s/re/dailyreport/detail',
        name: 'dailyDetails',
        component: () => import('@/views/erp/sells/reportforms/dailyreport/components/details.vue')
    },
    {
        path: 'erp/s/re/dailyreport/threelevel',
        name: 'threelevel',
        component: () => import('@/views/erp/sells/reportforms/dailyreport/threelevel/index.vue')
    },
    {
        path: 'erp/s/re/categoryreport/detail',
        name: 'categoryreport',
        component: () => import('@/views/erp/sells/reportforms/categoryreport/components/details.vue')
    },
    {
        path: 'erp/s/re/product/detail',
        name: 'productreportsd',
        component: () => import('@/views/erp/sells/reportforms/productreport/components/details.vue')
    },
    {
        path: 'erp/s/re/monthlyreporting/detail',
        name: 'monthlyreporting',
        component: () => import('@/views/erp/sells/reportforms/monthlyreporting/components/details.vue')
    },
    {
        path: 'erp/taskset/dayTask',
        name: 'taskDays',
        component: () => import('@/views/erp/sells/taskset/components/dayTask.vue')
    },
    {
        path: 'erp/s/re/returnorderreport/detail',
        name: 'dailyDetails',
        component: () => import('@/views/erp/sells/reportforms/returnorderreport/components/details.vue')
    },
    {
        path: 'erp/sells/split',
        name: 'split',
        component: () => import('@/views/erp/sells/split/index.vue')
    },
]
