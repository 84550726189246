/** When your routing erp is too long, you can split it into small modules **/
import Login from '@/views/sys/Login.vue'
import Register from '@/views/sys/Register.vue'
import ResetPassword from '@/views/sys/userCenter/resetPassword.vue'
import OrderBlank from '@/views/erp/ship/orderblank/index.vue'
export default [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/orderblank',
    name: 'OrderBlank',
    component: OrderBlank
  }
]
