/** When your routing erp is too long, you can split it into small modules **/
export default [
  {
    path: 'e/promotional/a/shops',
    name: 'promotionalActiveShops',
    component: () => import('@/views/erp/promotional/active/shops.vue')
  },
  {
    path: 'e/promotional/a/condition',
    name: 'promotionalActiveCondition',
    component: () => import('@/views/erp/promotional/active/condition.vue')
  },
  {
    path: 'e/promotional/promotionstr',
    name: 'promotionStrategy',
    component: () => import('@/views/erp/promotional/promotionstr/index.vue')
  },
  {
    path: 'e/promotional/timeslot',
    name: 'application_timeslot',
    component: () => import('@/views/erp/promotional/promotionstr/timeslot.vue')
  },
  {
    path: 'e/promotional/sameorigin',
    name: 'application_sameorigin',
    component: () => import('@/views/erp/promotional/promotionstr/sameorigin.vue')
  },
  {
    path: 'e/promotional/member',
    name: 'application_member',
    component: () => import('@/views/erp/promotional/promotionstr/member.vue')
  },
]
