export default [
    {
        path: '/e/s/o/t/c/c',
        name: 'transferorder_add',
        component: () => import('@/views/erp/storeend/ordergoods/transferorder/components/create.vue'),
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/e/p/s/o/t/c',
        name: 'transferorder_details',
        component: () => import('@/views/erp/storeend/ordergoods/transferorder/components/details.vue')
    },
    //代理商财务-订单详情
    {
        path: '/e/s/f/c/d',
        name: 'finance_details',
        component: () => import('@/views/erp/storeend/finance/components/details.vue')
    }
]