import router from '@/router'

export const hasPerm = {
  install: (app) => {
    app.directive('hasPerm', {
      mounted(el, binding) {
        let flag = false
        if (router.currentRoute.value.fullPath) {
          if (router.currentRoute.value.meta['permissions']) {
            var permissions = router.currentRoute.value.meta.permissions
            if (binding.arg) {
              const value = binding.arg
              flag = permissions.includes(value)
            } else {
              flag = false
            }
          }
        } else {
          flag = false
        }
        if (!flag) {
          if (!el.parentNode) {
            el.style.display = 'none'
          } else {
            el.parentNode.removeChild(el)
          }
        }
      }
    })
  }
}
