<template>
  <div class="header-right-content">
    <!-- <div class="message">
      <remind theme="outline" size="16" fill="#333" />
    </div>
    <div class="phone">
      <headset-one theme="outline" size="16" fill="#333" />
    </div> -->
    <div class="flex flex-items-center">
      <!-- <el-button>-</el-button>
      <el-button>+</el-button> -->
      <el-button size="small" type="primary" @click="onChangeCacheModel"
        >切换为{{ !isCache ? '缓存模式' : '普通模式' }}</el-button
      >
    </div>
    <div class="avatar">
      <el-dropdown>
        <span class="el-dropdown-link">
          <el-avatar :src="image"></el-avatar>
          <span class="user-name">{{ name }}</span>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="goTocenter">绑定微信号</el-dropdown-item>
            <el-dropdown-item @click="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
  <!-- 注销账号 -->
</template>
<script>
import request from '@/utils/request'
import { Remind, HeadsetOne } from '@icon-park/vue-next'
import userApi from '@/api/sys/admin/userApi'
/* 清除缓存 */
import { menuApi } from '@/api/sys/admin/menuApi'
import store from '@/store/index'
export default {
  name: 'HeaderAvatar',
  components: {
    Remind,
    HeadsetOne
  },
  computed: {
    isCache() {
      return store.state?.routerStore?.cacheAll
    }
  },
  data() {
    return {
      bindVisible: false,
      image: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
      name: ''
    }
  },
  created() {
    this.getData()
  },
  methods: {
    onChangeCacheModel() {
      store.dispatch('routerStore/setCacheAll', !store.state.routerStore.cacheAll)
    },
    clearCath() {
      menuApi.cleanUserCache()
    },
    goTocenter() {
      this.$router.push({
        path: '/usercenter',
        query: {
          title: '个人中心',
          path: '/usercenter'
        }
      })
    },
    logout() {
      this.clearCath()
      store.dispatch('routerStore/clearRouter')
      store.dispatch('cachePageStore/removeAllCache')
      request.get('/auth/apilogout').then((res) => {
        if (res.data == 'success') {
          this.$message({
            type: 'success',
            message: '登出成功!'
          })
          window.loadflog.hasload = false
          localStorage.removeItem('jsessionid')
          this.$router.push({ path: '/login' })
        } else {
          this.$message({
            type: 'error',
            message: '登出失败!'
          })
        }
      })
    },
    getData() {
      let self = this
      userApi.getInfo().then((response) => {
        if (response && response.data) {
          if (response.data.image) {
            let url = response.data.image
            url = url.replace(
              'photo/',
              // eslint-disable-next-line no-undef
              `${process.env.VUE_APP_PHOTO_SERVICE}/`
            )
            self.image = url
          }
          if (response.data.name) {
            self.name = response.data.name
          }
        }
      })
    }
  }
}
</script>
<style>
.phone,
.message {
  line-height: 34px;
}

.phone,
.message .i-icon {
  display: block;
  padding: 0px 12px;
}

.phone > .i-icon > svg,
.message > .i-icon > svg {
  vertical-align: middle;
}

.avatar {
  padding: 0px 12px;
  line-height: 34px;
  display: flex;
  align-items: center;
}
.header-right-content {
  display: flex;
  align-items: center;
}
.header-right-content .el-avatar {
  width: 24px;
  height: 24px;
}
.user-name {
  font-size: 13px;
  color: #2a2a2a;
  margin-left: 8px;
}
</style>
