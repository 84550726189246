/** When your routing erp is too long, you can split it into small modules **/
export default [
  // {
  //   path: '/e/p/sp/index',
  //   name: 'shopPlan',
  //   component: () => import('@/views/erp/planning/shopplan/index.vue')
  // },
  {
    path: 'e/p/sp/online',
    name: 'shoppPlanOnline',
    component: () => import('@/views/erp/planning/shopplan/online.vue')
  },
  {
    path: 'e/p/sp/video',
    name: 'shoppPlanVideo',
    component: () => import('@/views/erp/planning/shopplan/videos.vue')
  },
  {
    path: '/e/p/sp/bsh',
    name: 'shopPlanBatchSetting',
    component: () => import('@/views/erp/planning/shopplan/batch.vue')
  },
  {
    path: 'e/p/sp/detail',
    name: 'shopPlanDetail',
    component: () => import('@/views/erp/planning/shopplan/plandetails.vue')
  },
  {
    path: 'e/p/sp/materials',
    name: 'shopPlanMaterials',
    component: () => import('@/views/erp/planning/shopplan/materialList.vue')
  },
  {
    path: 'e/p/sp/shops',
    name: 'shopPlanShops',
    component: () => import('@/views/erp/planning/shopplan/shops.vue')
  },
  {
    path: '/e/p/sp/purchaseOrder',
    name: 'purchaseOrder',
    component: () => import('@/views/erp/planning/shopplan/purchaseOrder/index.vue')
  },
  {
    path: '/e/p/s/configuration',
    name: 'configuration',
    component: () => import('@/views/erp/planning/shopplan/components/configuration.vue')
  }
]
