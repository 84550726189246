/** When your routing erp is too long, you can split it into small modules **/
export default [
    {
      path: 'erp/agent/planning/orderplan',
      name: 'agentPlanningOrderPlan',
      component: () => import('@/views/erp/agent/planning/orderplan/index.vue')
    },

    {
      path: 'erp/a/p/sp/detail',
      name: 'agentShopPlanDetail',
      component: () => import('@/views/erp/agent/planning/shopplan/plandetails.vue')
    },

    {
      path: 'erp/a/p/oq/detail',
      name: 'agentOrderQuotaDetail',
      component: () => import('@/views/erp/agent/planning/orderquota/detail.vue')
    },
    
  ]
  