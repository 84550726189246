<template>
  <el-upload
    action="#"
    list-type="picture-card"
    :show-file-list="false"
    :multiple="false"
    accept=".png,.jpeg,.jpg,.bmp"
    :headers="headers"
    :http-request="uploadFile"
    :before-upload="beforeUpload"
    ref="uploadRef"
    auto-upload="true"
  >
    <!-- <img
        width="130"
        height="130"
        v-else
        :src="require('@/assets/image/empty/noimage40.png')"
        alt="暂无图片"
      /> -->
    <el-icon>
      <img width="130" height="130" v-if="resultImg" :src="resultImg" alt="货品图片" />
      <Plus v-else />
    </el-icon>
  </el-upload>
</template>

<script>
export default {
  name: 'UploadIndex'
}
</script>

<script setup>
import { toRefs, ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import fileApi from '@/api/common/fileApi'
import { Plus } from '@element-plus/icons-vue'

let props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  imgUrl: {
    type: String,
    default: ''
  },
  path: {
    type: String,
    default: ''
  }
})
const { imgUrl, path } = toRefs(props)
let resultImg = computed(() => {
  return imgBase64.value || imgUrl.value
})

let emit = defineEmits(['update:modelValue', 'change'])
let headers = { 'Content-Type': 'multipart/form-data' }
const imgBase64 = ref('')

const successMsg = () => {
  ElMessage({
    message: '上传成功！',
    type: 'success'
  })
}
const errorMsg = () => {
  ElMessage.error('上传失败！')
}

//文件上传之前
function beforeUpload(file) {
  //计算文件的大小
  const isLt5M = file.size / 1024 < 5120 //这里做文件大小限制
  //如果大于50M
  if (!isLt5M) {
    ElMessage({
      message: '上传文件大小不能超过 5MB!!',
      type: 'error'
    })
    return false
  } else {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = (result) => {
      imgBase64.value = result.target.result
    }
    return true
  }
}

function uploadFile(item) {
  let formData = new FormData()
  formData.append('file', item.file)
  formData.append('path', path.value)
  fileApi.uploadImage(formData).then(function (res) {
    if (res.data) {
      if (res?.data?.id) {
        emit('update:modelValue', res.data.id)
        emit('change', res.data)
        successMsg()
      } else {
        errorMsg()
      }
    }
  })
}
</script>
