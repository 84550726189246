import createPersistedState from 'vuex-persistedstate';
const state = {
    logoUrl: null
}
const mutations = {
    setLogoUrl(state, url) {
        state.logoUrl = url;
    }
}
const actions = {
}
const getters = {
    logoUrl: state => state.logoUrl,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
    plugins: [createPersistedState()]
}
