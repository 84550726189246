/** When your routing erp is too long, you can split it into small modules **/
export default [
  //  订货会  ——> 查看订单 
  {
    path: '/e/s/or/purch',
    name: 'purchaseorder',
    component: () => import('@/views/erp/storeend/ordergoods/purchaseorder/index.vue')
  },
  // 订货会 ——> 可预产品
  {
    path: '/e/s/or/pred',
    name: 'predictable',
    component: () => import('@/views/erp/storeend/ordergoods/predictable/index.vue')
  },
  // 订货会 ——> 代理商给门店订购商品
  {
    path: '/e/s/or/agentordering',
    name: 'agentordering',
    component: () => import('@/views/erp/storeend/ordergoods/predictable/components/agentordering.vue')
  },
  //订货会 ——> 可预产品详情
  {
    path: '/e/s/o/p/details',
    name: 'productDetails',
    component: () => import('@/views/erp/storeend/ordergoods/predictable/components/productDetails.vue')
  },
  // 订货会 ——> 购物车
  {
    path: '/e/s/or/pr/c',
    name: 'ShoppingCart',
    component: () => import('@/views/erp/storeend/ordergoods/predictable/components/shoppingCart.vue')
  },
  //订购会 ——>查看订单 ——详情
  {
    path: '/e/s/o/p/c/d',
    name: 'DetailPages',
    component: () => import('@/views/erp/storeend/ordergoods/purchaseorder/details/index.vue')
  },
  //订货会 ——>查看订单 —— 查看更多
  {
    path: '/e/s/o/p/d/c/seemore',
    name: 'seemore',
    component: () => import('@/views/erp/storeend/ordergoods/purchaseorder/details/components/seemore.vue')
  },
  //订货会 ——>翻单 ——>购物车
  {
    path: '/e/s/o/f/c/shoppingChart',
    name: 'shopping_Chart',
    component: () => import('@/views/erp/storeend/ordergoods/flippingorders/components/shoppingcCart.vue')
  },
  //仓库——>库存盘点——>添加盘点
  {
    path: '/e/s/w/i/a',
    name: 'Addinvent',
    component: () => import('@/views/erp/storeend/warehouse/Inventory/addinventory/index.vue')
  },
  //仓库——>库存盘点——>盘点详情
  {
    path: '/e/s/w/i/d',
    name: 'Inventorydetails',
    component: () => import('@/views/erp/storeend/warehouse/Inventory/details/index.vue')
  },
  //代理商-订货单首页
  {
    path: '/e/a/plan/order',
    name: 'Orderbill',
    component: () => import('@/views/erp/agent/planning/orderbill/index.vue')
  },
  //代理商-订货单-订单汇总
  {
    path: '/e/a/p/s/o',
    name: 'Inventorydetails',
    component: () => import('@/views/erp/agent/planning/shopplan/orderSummary.vue')
  },
  //品牌商-订货单-订单汇总
  {
    path: '/e/p/s/p/summary',
    name: 'summarydetails',
    component: () => import('@/views/erp/planning/shopplan/purchaseOrder/components/summary.vue')
  },
  //品牌商-取消发货-详情
  {
    path: '/e/p/s/p/shippingdetails',
    name: 'shippingdetails',
    component: () => import('@/views/erp/planning/shopplan/purchaseOrder/components/shippingdetails.vue')
  },
  //品牌商-生成出库单
  {
    path: '/e/p/s/p/generateoutbound',
    name: 'generateoutbound',
    component: () => import('@/views/erp/planning/shopplan/purchaseOrder/components/generateoutbound.vue')
  },
  /*
    订货会-退货单
  */
  //添加退货单
  {
    path: '/e/s/a/addreturn',
    name: 'addreturn',
    component: () => import('@/views/erp/storeend/ordergoods/returnOrder/addreturn.vue')
  },
  //退货单详情
  {
    path: '/e/s/or/returnDetails',
    name: 'returnDetails',
    component: () => import('@/views/erp/storeend/ordergoods/returnOrder/returnDetails.vue')
  },
  /*
  订货会-换货单
*/
  //添加换货单
  {
    path: '/e/s/o/e/c/t',
    name: 'swaporder_add',
    component: () => import('@/views/erp/storeend/ordergoods/exchange/components/create.vue')
  },
  {
    path: '/e/s/o/e/c/d',
    name: 'swaporder_details',
    component: () => import('@/views/erp/storeend/ordergoods/exchange/components/details.vue')
  },
  {
    path: '/e/p/s/e/c/t',
    name: 'brand_add',
    component: () => import('@/views/erp/planning/swaporder/components/create.vue')
  },
  /*
  订货会-翻单
*/
  //  翻单  ——> 查看订单汇总
  {
    path: '/e/f/o/c/summary',
    name: 'flipping_summary',
    component: () => import('@/views/erp/fippingorders/orderform/components/summary.vue')
  },
  //  翻单  ——> 查看订单汇总 ——>修改数量
  {
    path: '/e/f/o/c/modifyQuantity',
    name: 'flipping_modifyQuantity',
    component: () => import('@/views/erp/fippingorders/orderform/components/modifyQuantity.vue')
  },
  //  翻单  ——> 生成出库单
  {
    path: '/e/f/o/c/generateoutbound',
    name: 'flipping_generateoutbound',
    component: () => import('@/views/erp/fippingorders/orderform/components/generateoutbound.vue')
  },
  //  翻单  ——> 详情
  {
    path: '/e/f/o/c/details',
    name: 'flipping_details',
    component: () => import('@/views/erp/fippingorders/orderform/components/details.vue')
  },
  //  翻单  ——> 详情 ——> 更多
  {
    path: '/e/f/o/c/seemore',
    name: 'flipping_seemore',
    component: () => import('@/views/erp/fippingorders/orderform/components/seemore.vue')
  },
]
