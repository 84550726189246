<template>
  <div
    class="flex flex-items-center flex-content-center"
    :style="`width: ${computedWidth}px;height: ${computedHeight}px`"
  >
    <el-popover
      v-if="url"
      placement="right"
      :width="400"
      trigger="hover"
      @before-enter="beforeEnter"
    >
      <template #reference>
        <el-image :src="smallPicUrl" fit="contain"></el-image>
      </template>
      <vue-photo-zoom-pro v-if="hovered" :url="url" :high-url="url"> </vue-photo-zoom-pro>
      <div v-if="skc" class="p5 fs-16 strong">{{ skc }}</div>
    </el-popover>
    <el-image v-else :src="defaultImg"></el-image>
  </div>
</template>
<script setup>
import { toRefs, onMounted, ref, computed, watch } from 'vue'
import defaultImg from '@/assets/image/empty/noimage40.png'

const props = defineProps({
  // useForExport: {
  //   type: Boolean,
  //   default: false
  // },
  url: { type: String, default: '' },
  skc: { type: String, default: '' },
  width: { type: String || Number, default: '40px' },
  height: { type: String || Number, default: '40px' }
})
const { skc, url, width, height } = toRefs(props)
const smallPicUrl = ref('')
const hovered = ref(false)
const computedWidth = computed(() => {
  const w = Math.floor(props.width.replace('px', '')) - 24
  return w < 40 ? 40 : w
})
const computedHeight = computed(() => {
  const w = Math.floor(props.height.replace('px', '')) - 24
  return w < 40 ? 40 : w
})
const beforeEnter = () => {
  hovered.value = true
}
// /**
//  * 通过图片url将图片转为base64格式。
//  */
// const getBase64Image = (url) => {
//   const img = new Image()
//   //因为是网络资源所以会有图片跨域问题产生，此属性可以解决跨域问题，下文详解
//   img.setAttribute('crossOrigin', 'anonymous')
//   //如果需要兼容ios，这两个顺序一定不能换，先设置crossOrigin后设置src
//   img.src = url
//   let base64Width = 200
//   return new Promise((resolve) => {
//     img.onload = () => {
//       //canvas基本配置
//       const canvas = document.createElement('canvas')
//       canvas.width = props.useForExport ? base64Width : img.width
//       canvas.height = props.useForExport ? base64Width : img.height
//       const ctx = canvas.getContext('2d')
//       ctx.drawImage(
//         img,
//         0,
//         0,
//         props.useForExport ? base64Width : img.width,
//         props.useForExport ? base64Width : img.height
//       )
//       resolve({
//         success: true,
//         base64: canvas.toDataURL()
//       })
//     }
//     img.onerror = () => {
//       const canvas = document.createElement('canvas')
//       canvas.width = props.useForExport ? base64Width : defaultImg.width
//       canvas.height = props.useForExport ? base64Width : defaultImg.height
//       const ctx = canvas.getContext('2d')
//       ctx.drawImage(
//         defaultImg,
//         0,
//         0,
//         props.useForExport ? base64Width : defaultImg.width,
//         props.useForExport ? base64Width : defaultImg.height
//       )
//       resolve({
//         success: true,
//         base64: canvas.toDataURL()
//       })
//     }
//   })
// }
onMounted(() => {
  /* if (props.useForExport) {
    if (props.url) {
      // getBase64Image(props.url).then((res) => {
      //   if (res.success) {
      //     smallPicUrl.value = res.base64
      //   }
      // })
      smallPicUrl.value = `${props.url}-200.jpg`
    }
  } else {
    smallPicUrl.value = props.url
  } */
  // smallPicUrl.value = `${props.url}-200.jpg`
  if (props.url) {
    smallPicUrl.value = `${props.url}-200.jpg`
  } else {
    smallPicUrl.value = defaultImg
  }
})
watch(url, (newUrl, oldUrl) => {
  if (newUrl !== oldUrl) {
    smallPicUrl.value = `${newUrl}-200.jpg`
  }
})
</script>
