/** When your routing erp is too long, you can split it into small modules **/
export default [
    {
      path: 'erp/s/shopsellstatist/detail',
      name: 'erpshopsellstatistDetail',
      component: () => import('@/views/erp/sells/shopsellstatist/detail.vue')
    },
    {
      path: 'erp/s/shopsellstatist/pk',
      name: 'erpshopsellstatistPk',
      component: () => import('@/views/erp/sells/shopsellstatist/pk.vue')
    }
  ]
  