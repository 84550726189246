const state = {
  router: [],
  cacheAll: false
}

const mutations = {
  SET_CACHE_ALL: (state,data) => {
    state.cacheAll = data
  },
  SET_ROUTER: (state, data) => {
    state.router = data
  },
  CLEAR_ROUTER: (state) => {
    state.router.splice(0)
  }
}

const actions = {
  setCacheAll({ commit }, data) {
    commit('SET_CACHE_ALL', data)
  },
  setRouter({ commit }, data) {
    commit('SET_ROUTER', data)
  },
  clearRouter({ commit }) {
    commit('CLEAR_ROUTER')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
