<template>
  <el-container>
    <el-aside>
      <AsideMenu />
    </el-aside>
    <el-container>
      <el-header>
        <div class="top-nav">
          <HeaderTab />
          <HeaderAvatar />
        </div>
      </el-header>
      <el-main>
        <router-view v-slot="{ Component }" style="flex: 1; height: 100%">
          <keep-alive>
            <component :is="Component" v-if="isKeepAlive" :key="$route.path" />
          </keep-alive>
          <component :is="Component" v-if="!isKeepAlive" />
        </router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script setup>
import HeaderTab from './components/Header/HeaderTab.vue'
import HeaderAvatar from './components/Header/HeaderAvatar.vue'
import AsideMenu from './components/Aside/AsideMenu.vue'
import store from '@/store/index'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const isKeepAlive = computed(
  () =>
    route.meta.keepAlive ||
    (store && store.state && store.state.routerStore && store.state.routerStore.cacheAll)
)
</script>
<style>
.el-header {
  height: 34px !important;
}
.el-aside {
  width: 64px !important;
}
.el-main {
  padding: 0px !important;
  min-width: 1280px;
}
.top-nav {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 64px;
  z-index: 998;
  padding: 0px 24px;
}
.top-nav {
  display: flex;
  background-color: #fff;
  align-items: center;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
}
</style>
