import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout/index.vue'
import { menuApi } from '@/api/sys/admin/menuApi'
import store from '@/store/index'
import sysRouter from './modules/sysRouter'
import erp from './modules/erp'
import users from './modules/users'
import shopplan from './modules/shopplan'
import storeend from "./modules/storeend"
import agentRoute from './modules/agent'
import orderplan from './modules/orderplan'
import specialDiscount from './modules/specialDiscount'
import promotional from './modules/promotional'
import transferorder from "./modules/transferorder"
import member from './modules/member'
import sells from './modules/sells'
import coupon from './modules/coupon'
import reportforms from './modules/reportforms'
const routes = [
  {
    path: '/',
    name: 'index',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        meta: { keepAlive: true },
        component: () => import('@/views/dashboard/index.vue')
      },
      {
        path: 'usercenter',
        name: 'UserCenter',
        component: () => import('@/views/sys/userCenter/index.vue')
      },
      ...erp,
      ...shopplan,
      ...storeend,
      ...transferorder,
      ...agentRoute,
      ...users,
      ...orderplan,
      ...promotional,
      ...specialDiscount,
      ...member,
      ...coupon,
      ...sells,
      ...reportforms,
    ]
  },
  ...sysRouter
]

const router = createRouter({
  // eslint-disable-next-line no-undef
  history: createWebHistory(process.env.BASE_URL),
  routes
})
window.loadflog = { hasload: false }
const whitePath = ['/authresult', '/login', '/register', '/resetPassword']

router.beforeEach(async (to, from, next) => {
  //跳转前 获取到当前页面的路由
  if (whitePath.includes(to.path)) {
    // 判断该路由是否需要登录权限
    // if (!sessionStorage.getItem('old_url_before_login')) {
    //   sessionStorage.setItem('old_url_before_login', from.path)
    // }
    next()
  } else {
    if (window.loadflog.hasload) {
      next()
    } else if (store && store.state.routerStore.router.length == 0) {
      let result = await menuApi.getRoute()
      if (
        result == undefined ||
        result.code == 'A0231' ||
        result.code == 'A0200' ||
        result.code == 'S0003' ||
        result.code == 'S0002' ||
        result.code == 'S0001'
      ) {
        localStorage.removeItem('jsessionid')
        // if (!sessionStorage.getItem('old_url_before_login')) {
        //   sessionStorage.setItem(
        //     'old_url_before_login',
        //     window.location.pathname + window.location.search
        //   )
        // }
        router.push('/login')
      } else {
        const transData = replaceName(result.data || [])

        store.dispatch('routerStore/setRouter', transData)
        addRoute(router, transData)
        window.loadflog.hasload = true
        next({ ...to, replace: true })
      }
    } else {
      next()
    }
  }
})

function replaceName(data) {
  if (Array.isArray(data) && data.length > 0) {
    return data.map(item => {
      return replaceName(item)
    })
  } else {
    if (data.meta && data.meta.title) {
      const title = (data.meta.title || '').replace(/\{(\s)*\{.*\}(\s)*\}/g, '')
      data.meta.title = title
    }
    if (data.children && data.children.length > 0) {
      data.children = replaceName(data.children)
    }
    return data
  }
}
function addRoute(router, data) {
  data.forEach((v) => {
    let item = {}
    let needroute = false
    item.path = 'home'
    item.name = v.name
    if (v['redirect']) {
      item.redirect = v.redirect
      needroute = true
    } else {
      if (v['path']) {
        item.path = v.path
        needroute = true
        if (v['component']) {
          item.component = () => import('@/views/' + v.component)
        }
      } else {
        needroute = false
      }
    }
    item.meta = v.meta
    if (needroute) {
      // if (item.meta) {
      //   item.meta.keepAlive = true
      // } else {
      //   item.meta = { keepAlive: true }
      // }
      router.addRoute('index', item)
    }
    if (v['children']) {
      addRoute(router, v.children)
    }
  })
}
export default router
