/** When your routing erp is too long, you can split it into small modules **/
export default [
    {
      path: 'e/p/sd/agent',
      name: 'planSpecialDiscountAgent',
      component: () => import('@/views/erp/planning/specialDiscount/agent.vue')
    },

    {
      path: 'e/p/sd/shop',
      name: 'planSpecialDiscountShop',
      component: () => import('@/views/erp/planning/specialDiscount/shop.vue')
    },
    
    {
      path: 'e/p/sd/condition',
      name: 'planSpecialDiscountCondition',
      component: () => import('@/views/erp/planning/specialDiscount/condition.vue')
    },

  ]
  