<template>
  <div class="global-table-container">
    <el-table :id="uuid" :data="curTableData.records" ref="globalTableRef" v-loading="loading" :default-sort="defaultSort"
      :stripe="stripe" :border="border" :height="height" :show-summary="showSummary" :summary-method="summaryMethod"
      :row-class-name="rowClassName" @row-click="rowClick" :row-key="rowKey" @sort-change="tableSort"
      @expand-change="expandChange" @selection-change="selectChange" style="width: 100%; margin-bottom: 16px">
      <slot name="field"></slot>
    </el-table>

    <el-pagination background layout="total, sizes, prev, next, jumper" :total="curTableData.total"
      :page-sizes="[10, 20, 50, 100]" :page-size="pagesize" style="margin-left: auto" :current-page="currentPage"
      @size-change="handleSizeChange" @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'NewGlobalTable'
}
</script>

<script setup>
import { reactive, ref, watch, onMounted, toRefs, computed, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { tableHeaderFloat, screenToTop } from '@/utils/jquery/table/float-header'
import { guid } from '@/utils/index'
let props = defineProps({
  tableData: { type: Object, default: () => ({ records: [], total: '' }) },
  queryParams: {},
  inDialog: Boolean,
  inTable: Boolean,
  defaultSort: Object,
  stripe: Boolean,
  border: Boolean,
  size: { type: String, default: '' },
  height: String || Number,
  showSummary: Boolean,
  summaryMethod: Function,
  rowKey: Function,
  rowClassName: Function
})
const {
  tableData,
  defaultSort,
  stripe,
  border,
  height,
  showSummary,
  summaryMethod,
  rowKey,
  rowClassName
} = toRefs(props)
const curTableData = computed(() => {
  return tableData.value ? tableData.value : mTableData.value
})
const emit = defineEmits(['loadTable', 'selectionChange', 'row-click', 'expandChange'])
let globalTableRef = ref()
const state = reactive({
  pagesize: 10,
  currentPage: 1,
  mTableData: { records: [], total: '' },
  mQueryParams: { limit: 10, page: 1 },
  loading: false,
  uuid: guid()
})
const { uuid, pagesize, currentPage, mTableData, loading } = toRefs(state)
let route = useRoute()
function handleSizeChange(size) {
  state.pagesize = size
  state.mQueryParams.limit = size
  state.mQueryParams.page = 1
  state.currentPage = 1
  refreshTable()
}

function tableSort(option) {
  state.mQueryParams.sort = option.prop
  state.mQueryParams.order = option.order == 'ascending' ? 'asc' : 'desc'
  state.mQueryParams.page = 1
  state.currentPage = 1
  refreshTable()
}
function handleCurrentChange(page) {
  state.currentPage = page
  state.mQueryParams.page = page
  refreshTable()
}
const callback = function (res) {
  if (props.tableData) {
    if (res && res['records'] && res['total']) {
      tableData.value.records = res.records
      tableData.value.total = res.total
    } else if (res && res['data'] && res['data']['records'] && res['data']['total']) {
      tableData.value.records = res.data.records
      tableData.value.total = res.data.total
    } else {
      tableData.value.records = []
      tableData.value.total = 0
    }
  } else {
    if (res && res['records'] && res['total']) {
      state.mTableData.records = res.records
      state.mTableData.total = res.total
    } else if (res && res['data'] && res['data']['records'] && res['data']['total']) {
      state.mTableData.records = res.data.records
      state.mTableData.total = res.data.total
    } else {
      state.mTableData.records = []
      state.mTableData.total = 0
    }
  }
  state.loading = false
  nextTick(() => {
    if (globalTableRef.value.scrollBarRef && globalTableRef.value.scrollBarRef.update) {
      globalTableRef.value.scrollBarRef.update()
    }
  })
}
function refreshTable() {
  state.loading = true
  state.mQueryParams.loadType = 'refreshTable'
  emit('loadTable', state.mQueryParams, callback)
  if (!props.height) {
    screenToTop()
  }
}

function loadTable(param) {
  state.loading = true
  state.mQueryParams.page = 1
  state.currentPage = 1
  if (param) {
    state.mQueryParams = Object.assign(state.mQueryParams, param)
  }
  state.mQueryParams.loadType = 'loadTable'

  emit('loadTable', state.mQueryParams, callback)
  if (!props.height) {
    screenToTop()
  }
}
function selectChange(selection) {
  emit('selectionChange', selection)
  emit('selection-change', selection)
}
function toggleRowExpansion(row) {
  globalTableRef.value.toggleRowExpansion(row)
}
function expandChange(row, expandedRows) {
  emit('expandChange', row, expandedRows)
}
function rowClick(row, column, event) {
  emit('row-click', row, column, event)
}
onMounted(() => {
  if (props.inDialog) {
    refreshTable()
  }
  if (props.size) {
    state.pagesize = props.size
    state.mQueryParams.limit = props.size
  }
  if (props.defaultSort) {
    state.mQueryParams.sort = props.defaultSort.prop
    state.mQueryParams.order = props.defaultSort.order == 'ascending' ? 'asc' : 'desc'
  }
})
defineExpose({ loadTable, toggleRowExpansion })
watch(props.queryParams, () => {
  loadTable(props.queryParams)
})
watch(route, () => {
  tableHeaderFloat(state.uuid)
})
watch(props.tableData, () => {
  state.loading = false
  if (!props.height && !props.inTable) {
    tableHeaderFloat(state.uuid)
  }
  nextTick(() => {
    if (globalTableRef.value.scrollBarRef && globalTableRef.value.scrollBarRef.update) {
      globalTableRef.value.scrollBarRef.update()
    }
  })
})
</script>

<style>
.floathead {
  position: fixed;
  z-index: 100;
  top: 34px;
}
</style>
