const state = {
  dataSource: {}
}
const mutations = {
  SET_DATA_SOURCE:(state,data) => {
    state.dataSource = data
  },
  SET_DATA_SOURCE_BY_KEY:(state,data) => {
    const {key,list}= data  
    state.dataSource[key]=list
  }
}

const actions = {
  setDataSource({commit},data){
    commit('SET_DATA_SOURCE',data)
  },
  setDataSourceByKey({commit},data){
    commit('SET_DATA_SOURCE_BY_KEY',data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
  