export default [
    {
        path: 'e/coupon/a/condition',
        name: 'erpCouponeActiveCondition',
        component: () => import('@/views/erp/coupon/active/condition.vue')
    },
    {
        path: 'e/coupon/a/shops',
        name: 'erpCouponeActiveShops',
        component: () => import('@/views/erp/coupon/active/shops.vue')
    },
    {
        path: 'e/coupon/a/shareconfig',
        name: 'erpCouponeActiveShareConfig',
        component: () => import('@/views/erp/coupon/active/shareconfig.vue')
    },
    {
        path: 'e/coupon/a/person',
        name: 'erpCouponeActivePerson',
        component: () => import('@/views/erp/coupon/active/person.vue')
    },
]
    
