export default [
    {
        path: 'e/m/s/c/cardlvel',
        name: 'cardlvel',
        component: () => import('@/views/erp/membership/system/components/cardlvel.vue')
    },
    {
        path: 'e/m/s/c/consumplevel',
        name: 'Deconsumpleve',
        component: () => import('@/views/erp/membership/system/components/consumplevel.vue')
    },
    {
        path: '/e/m/s/c/appstore',
        name: 'appstore',
        component: () => import('@/views/erp/membership/system/components/appstore.vue')
    },
    {
        path: '/e/m/s/c/rechargeactivity',
        name: 'rechargeactivity',
        component: () => import('@/views/erp/membership/system/components/rechargeactivity.vue')
    },
    {
        path: '/e/m/s/member',
        name: 'memberInformation',
        component: () => import('@/views/erp/membership/system/member.vue')
    },
    {
        path: '/e/m/s/m/addmember',
        name: 'addmemberdDetails',
        component: () => import('@/views/erp/storeend/member/addmember/components/details.vue')
    },
]