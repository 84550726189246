<template>
  <el-table ref="globalTableRef" v-bind="$attrs" :data="tableData">
    <slot></slot>
  </el-table>
</template>


<script setup>
import { nextTick, watch, ref, toRefs } from 'vue'

const globalTableRef = ref()
const props = defineProps({
  tableData: {
    type: Array,
    default: () => []
  }
})
const { tableData } = toRefs(props)
watch(() => props.tableData, (v) => {
  nextTick(() => {
    if (globalTableRef.value.scrollBarRef && globalTableRef.value.scrollBarRef.update)
      globalTableRef.value.scrollBarRef.update()
  })
})
</script>
