/** When your routing erp is too long, you can split it into small modules **/
import store from '@/store';
export default [
  {
    path: 'shipment_handing/shipstep',
    name: 'shipstep',
    component: () => import('@/views/erp/ship/shipment_handing/shipstep/index.vue')
  },
  {
    path: 'shipment_handing/pehuo',
    name: 'pehuo',
    component: () => import('@/views/erp/ship/orderblank/index.vue')
  },
  {
    path: 'e/s/p',
    name: 'plan_submit',
    component: () => import('@/views/erp/ship/ship_plan/plan_submit/index.vue')
  },
  {
    path: 'e/s/p/e',
    name: 'eu_split',
    component: () => import('@/views/erp/ship/ship_plan/plan_submit/eu_split.vue')
  },
  {
    path: 'e/s/a',
    name: 'shiptongbu',
    component: () => import('@/views/erp/ship/shipment_handing/list/components/async.vue')
  },

  {
    path: 'transportation/edit',
    name: 'transportation_edit',
    component: () => import('@/views/erp/ship/transportation/edit_transportation.vue')
  },
  {
    path: 'transportation/details',
    name: 'transportation_details',
    component: () => import('@/views/erp/ship/transportation/details_transportation.vue')
  },
  // {
  //   path: 'material/photoupload',
  //   name: 'photoupload',
  //   component: () => import('@/views/erp/baseinfo/material/photoupload/index.vue')
  // },
  {
    path: 'localproduct/editinfo',
    name: 'edit',
    component: () => import('@/views/erp/baseinfo/material/editinfo/index.vue')
  },
  {
    path: 'material/details',
    name: 'details',
    component: () => import('@/views/erp/baseinfo/material/details/index.vue')
  },


  {
    path: 'e/w/sd/agent',
    name: 'warehouseSpecialDiscountAgent',
    component: () => import('@/views/erp/warehouse/specialDiscount/agent.vue')
  },

  {
    path: 'e/w/sd/shop',
    name: 'warehouseSpecialDiscountShop',
    component: () => import('@/views/erp/warehouse/specialDiscount/shop.vue')
  },

  {
    path: 'e/w/sd/condition',
    name: 'warehouseSpecialDiscountCondition',
    component: () => import('@/views/erp/warehouse/specialDiscount/condition.vue')
  },

  {
    path: 'e/w/o',
    name: 'outbound_add',
    component: () => import('@/views/erp/warehouse/outbound/components/create.vue'),
    meta: {
      keepAlive: true
    },
    // beforeEnter(to, from, next) {
    //   if (!store.getters.routeCacheEnabled) {
    //     // 如果缓存状态被禁用，则不缓存当前路由
    //     to.meta.keepAlive = false;
    //   }
    //   next();
    // }
  },
  {
    path: 'e/w/o/d',
    name: 'outbound_add_info',
    component: () => import('@/views/erp/warehouse/outbound/components/details.vue')
  },
  {
    path: 'e/w/o/edit',
    name: 'outbound_add_edit',
    component: () => import('@/views/erp/warehouse/outbound/components/edit.vue')
  },
  {
    path: 'e/w/o/difference',
    name: 'outbound_difference',
    component: () => import('@/views/erp/warehouse/outbound/components/difference.vue')
  },
  {
    path: 'e/w/i',
    name: 'inbound_add',
    component: () => import('@/views/erp/warehouse/inbound/components/create.vue'),
    meta: {
      keepAlive: true
    },
  },
  {
    path: 'e/w/i/d',
    name: 'inbound_add_info',
    component: () => import('@/views/erp/warehouse/inbound/components/details.vue')
  },
  {
    path: 'e/w/i/scanDetails',
    name: 'inbound_scanDetails',
    component: () => import('@/views/erp/warehouse/inbound/components/scanDetails.vue')
  },
  {
    path: 'e/w/s',
    name: 'stocktake_add',
    component: () => import('@/views/erp/warehouse/stocktake/components/create.vue')
  },
  {
    path: 'e/w/s/d',
    name: 'stocktake_details',
    component: () => import('@/views/erp/warehouse/stocktake/components/details.vue')
  },
  {
    path: 'e/w/o/s',
    name: 'overseas_stock_add',
    component: () => import('@/views/erp/warehouse/overseas/stock/components/create.vue')
  },
  {
    path: 'e/w/o/d',
    name: 'overseas_stock_details',
    component: () => import('@/views/erp/warehouse/overseas/stock/components/details.vue')
  },
  {
    path: 'e/w/t',
    name: 'transfer_order',
    component: () => import('@/views/erp/warehouse/transfer/components/create.vue')
  },
  {
    path: 'e/w/t/d',
    name: 'transfer_order_details',
    component: () => import('@/views/erp/warehouse/transfer/components/details.vue')
  },
  {
    path: 'e/w/e/c',
    name: 'exchange_create',
    component: () => import('@/views/erp/warehouse/exchange/components/create.vue')
  },
  {
    path: 'e/w/e/d',
    name: 'exchange_details',
    component: () => import('@/views/erp/warehouse/exchange/components/details.vue')
  },
]
