const state = {
  cache: {},
  cacheKeys: {
    transferorder: '/erp/storeend/ordergoods/transferorder', // 调拨单
    inbound: '/erp/warehouse/inbound', // 入库单
    outbound: 'erp/warehouse/outbound' // 出库单
  }
}
const mutations = {
  SET_CACHE_ALL_BY_KEY: (state, data) => {
    const { key, query, pageInfo } = data
    state.cache[key] = {
      query,
      pageInfo
    }
  },
  SET_CACHE_QUERY_BY_KEY: (state, data) => {
    const { key, query } = data
    state.cache[key] = { ...state.cache[key], query }
  },
  SET_CACHE_PAGEINFO_BY_KEY: (state, data) => {
    const { key, pageInfo } = data
    state.cache[key] = { ...state.cache[key], pageInfo }
  },
  REMOVE_ALL_CACHE: (state) => {
    state.cache = {}
  }
}

const actions = {
  setCacheAllByKey({ commit }, data) {
    commit('SET_CACHE_ALL_BY_KEY', data)
  },
  setCacheQueryByKey({ commit }, data) {
    commit('SET_CACHE_QUERY_BY_KEY', data)
  },
  setCachePageInfoByKey({ commit }, data) {
    commit('SET_CACHE_PAGEINFO_BY_KEY', data)
  },
  removeAllCache({ commit }) {
    commit('REMOVE_ALL_CACHE')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
