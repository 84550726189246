import { createStore } from 'vuex'
import routerStore from './modules/router.js'
import dataSourceStore from './modules/dataSource.js'
import sysBasicInfoStore from './modules/sysBasicInfo.js'
import cachePageStore from './modules/cachePage.js'
import request from '@/utils/request'
const store = createStore({
  state: { globalData: null, routeCacheEnabled: true },
  mutations: {
    setGlobalData(state, data) {
      state.globalData = data
    },
    setParamsData(state, data) {
      state.configurationData = data
    },
    setRouteCacheEnabled(state, value) {
      state.routeCacheEnabled = value
    }
  },
  actions: {
    fetchGlobalData(context) {
      //获取logo相关数据
      return new Promise((resolve, reject) => {
        request
          .get(`/admin/config/sysBasicInfo`)
          .then((res) => {
            context.commit('setGlobalData', res.data)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    toggleRouteCache({ commit }, value) {
      commit('setRouteCacheEnabled', value)
    }
  },
  getters: {
    routeCacheEnabled: (state) => state.routeCacheEnabled
  },
  modules: {
    routerStore,
    dataSourceStore,
    sysBasicInfoStore,
    cachePageStore
  }
})

export default store
